<template>
    <section>
        <modal ref="modalInfoClientes" titulo="" :user="cliente.avatar" no-aceptar tamano="modal-lg">
            <div class="row justify-center">
                <div class="">
                    <p class="mb-0 text-muted2 f-600 text-center">
                        {{ cliente.nombre }}
                    </p>
                    <p v-if="cliente.antiguedad" class="mb-0 text-muted2">
                        Cliente desde {{ cliente.antiguedad }}
                    </p>
                </div>
            </div>
            <div v-if="caso == 1 || caso == 4" class="row mx-0 justify-center mt-4 mb-2">
                <p class="mb-0 text-muted2 f-14 ">
                    {{$config.vendedor}}s con Mayores pedidos
                </p>
            </div>
            <div v-if="caso == 1 || caso == 4" class="row align-items-center">
                <div v-for="(tienda, t) in tiendas" :key="t" class="col-4 py-2">
                    <div class="row mx-0 align-items-center">
                        <img :src="tienda.logo_mini" class="br-12 border" style="width:71px;height:71px;object-fit:cover;" />
                        <div class="col px-2 py-2">
                            <p class="mb-0 text-blue f-600 f-22">
                                {{ tienda.cantidad }}
                            </p>
                            <p class="mb-0 text-muted2 f-14">
                                {{ tienda.nombre }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-center mt-5 mb-2">
                <p class="mb-0 text-muted2 f-14 ">
                    Productos en mayor número de pedidos
                </p>
            </div>
            <div class="row mb-4 align-items-center">
                <div v-for="(producto, p) in productos" :key="p" class="col-4 p-2">
                    <div class="row mx-0 align-items-center">
                        <img :src="producto.logo_mini" class="br-12 border" style="width:71px;height:71px;object-fit:cover;" />
                        <div class="col px-2 py-2">
                            <p class="mb-0 text-blue f-600 f-22">
                                {{ producto.cantidad }}
                            </p>
                            <p class="mb-0 text-muted2 f-14">
                                {{ producto.nombre }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import MetricasTeams from "~/services/metricas_teams"

export default {
    data(){
        return{
            cliente: {
                id: null,
                nombre: '',
                avatar: '',
                antiguedad: '',
            },
            caso: 0,
            id_tienda: 0,
            tiendas: [],
            productos: [],
        }
    },
    methods: {
        toggle(cliente,caso,id_tienda){
            this.cliente.id = cliente.id
            this.cliente.avatar = cliente.avatar
            this.cliente.antiguedad = cliente.antiguedad
            this.cliente.nombre = cliente.nombre
            this.caso = caso
            this.id_tienda = id_tienda
            this.$refs.modalInfoClientes.toggle();
            this.ver_cliente()
        },
        async ver_cliente(){
            try {
                let params = {
                    id_cliente: this.cliente.id,
                    id_tienda: this.id_tienda,
                    caso: this.cliente.id ? this.caso : this.caso == 5 ? 7 : 6
                }
                const { data } = await MetricasTeams.ver_cliente(params)
                this.tiendas = data.data.tiendas
                this.productos = data.data.productos

            } catch (error){

            }
        },

    }
}
</script>
